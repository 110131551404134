.main-nav {
  .nav-link {
    @extend .text-uppercase;
    @extend .text-nowrap;
    @extend .fw-bold;
  }
}

.event-status-bar {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .gap-3;
  @extend .mb-3;

  > div {
    @extend .px-2;
    @extend .flex-grow-1;
    @extend .rounded;
    @extend .fw-semibold;
    background-color: #babcbe;
    max-height: fit-content;
    line-height: 1.7;
  }

  .status-success {
    background-color: #64c141;
  }

  .status-current {
    background-color: #FCBB00;
  }
}

.event-timeline {
  background-color: #2b388f;
}
