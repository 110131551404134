dt {
  @extend .mb-2;
  @extend .text-secondary;
  font-weight: 500;

}

#debit-memos-modal #publisher-navigation a.nav-link {
  color: #fff;

  &.active {
     color: #434c69;
  }
}