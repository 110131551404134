body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-title {
  font-size: 2rem;
}

a {
  text-decoration: none;

  &:active,
  &:focus {
    outline: none;
  }
}

.game-cover {
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: var(--bs-border-radius);

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 150%;
    background-color: #f8f8f8;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
  }
}

.fs-7 {
  font-size: $font-size-sm !important;
}

.fs-8 {
  font-size: $font-size-base * .75 !important;
}
