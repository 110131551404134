.customdatepickerinput {
  min-width: 8rem;
  &[readonly] {
    background-color: $input-bg;
  }
  &[disabled] {
    background-color: #e9ecef;
  }
}
.customdatetimepickerinput {
  min-width: 10rem;
  &[readonly] {
    background-color: $input-bg;
  }
  &[disabled] {
    background-color: #e9ecef;
  }
}