// See https://github.com/twbs/bootstrap/blob/master/scss/_variables.scss for
// variables you can override.

// COLORS
$primary: #0061FF;
$secondary: #434c69;
$purple: #5e36fc;

// BORDERS
$border-radius: 5px;

// INPUTS
$input-btn-font-size: 14px;

// BUTTONS
$btn-font-weight: 500;
$btn-line-height: 16px;
$btn-padding-x: 10px;
$btn-padding-y: 10px;

// CARDS
$card-cap-bg: #fafafa;


$theme-color-interval: 5%;
$alert-color-level: 7;
$alert-bg-level: 2;
$alert-border-level: 5;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1616px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1536px
);

$breadcrumb-divider: quote(">");
$font-family-sans-serif: "Noto Sans", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
