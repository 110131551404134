.custom-select-multiple {
  .panel {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 3px;

    .panel-header {
      position: relative;
      padding: 5px 10px 10px 10px;
      background: rgba(211, 211, 211, .2);

      h2 {
        font-size: 1rem;
        font-weight: 600;
        margin: 0;
        padding: 2px 0 8px 0;
      }

      .left-filter {
        max-height: 77px;
        min-height: 35px;
      }

      .progress {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 3px;
        background-color: transparent;
      }
    }

    .panel-body {
      height: 250px;
      overflow: auto;
      padding: 0 10px;
      font-size: 0.85rem;

      >div {
        cursor: pointer;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding: 6px;
        border-bottom-width: 1px;
        border-bottom-color: rgba(211, 211, 211, .35);
        border-bottom-style: solid;

        &.active {
          color: #fff;
          background-color: rgb(25, 103, 210);

          .text-body-secondary {
            color: #dbdbdb !important;
          }
        }

        &.hide {
          display: none;
        }

        &.mask {
          display: none;
        }

        &.off {
          display: none;
        }
      }
    }
  }

  .customselectmultiple {
    min-height: 250px;
    border-width: 0;
    flex: 1;

    option {
      font-size: 0.75rem;
      padding: 6px;
      border-bottom-width: 1px;
      border-bottom-color: rgba(211, 211, 211, .35);
      border-bottom-style: solid;

      &.mask {
        display: none;
      }
    }
  }
}