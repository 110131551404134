.list-group > .list-group > .list-group-item {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  &:last-child {
    border-bottom: 0;
  }
}

.platform-variant-tab {
  &.active {
    background-color: mix($white, $list-group-active-bg, 5 * $theme-color-interval);
    border-color: mix($white, $list-group-active-bg, 5 * $theme-color-interval);
  }
}
