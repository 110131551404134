.table {
  thead {
    border: 0;

    th,
    a {
      color: $secondary;
    }
  }

  th {
    border: 0;

    &.asc {
      &::after {
        @extend .fa;
        content: fa-content($fa-var-caret-down);
        float: right;
        margin-top: 4px;
      }
    }

    &.desc {
      &::after {
        @extend .fa;
        content: fa-content($fa-var-caret-up);
        float: right;
        margin-top: 4px;
      }
    }
  }

  tfoot * {
    border: none;
  }
}