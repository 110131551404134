/* overrides to default admin and bootstrap styles */
.controls > p {
    padding-top: 5px;
}
.controls > ul,
.controls .related-widget-wrapper > ul {
    list-style-type: none;
    padding-left: 0px;
}
.controls > ul label,
.controls .related-widget-wrapper > ul label {
    font-size: 14px;
}
.controls > ul .form-control,
.controls .related-widget-wrapper > ul .form-control {
    height: auto;
    margin: 9px 0;
}
.controls .related-widget-wrapper {
    float: none;
}
.controls .related-widget-wrapper-link {
    display: inline-block;
    margin: 7px 7px 3px 7px;
}

/* SELECTOR (FILTER INTERFACE) */
.selector {
  width: 100%;
  float: none;
}
.selector .selector-filter {
    border: 0px solid #ccc;
    border-width: 0 0px;
    padding: 4px 0px 4px 0px;
}
.selector .selector-filter label {
    display: none;
}
.selector select {
    height: 17.2em;
    width: 100%; /* fix offscreen scroll-bar on selector-chosen */
    border: 1px #ccc solid;
}
.selector .selector-chosen select {
    border-top: 0;
}
/* fix offscreen scroll-bar on selector-chosen */
.selector-available, .selector-chosen {
    width: 47%;
}
/* selector object list */
.selector > .selector-available > select, .selector > .selector-chosen > select {
    font-size: 12px;
    color: #666;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.075) inset;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
/* selector object list items */
.selector > .selector-available > select > option, .selector > .selector-chosen > select > option {
    padding: 6px;
    border-bottom-width: 1px;
    border-bottom-color: rgba(211, 211, 211, 0.35);
    border-bottom-style: solid
}
/* selector field title */
.selector > .selector-available > h2, .selector > .selector-chosen > h2 {
    text-align: left;
    background: rgba(211, 211, 211, 0.2);
    color: #777;
    border: 1px solid #ccc;
    border-bottom: none;
    font-size: 100%;
    font-weight: 600;
    margin: 0px;
    padding: 10px 0px 6px 10px;
    height: 36px;
    border-radius: 4px 4px 0px 0px;
}
/* selector filter box bootstrapping */
.selector .selector-available input, .selector .selector-chosen input {
    width: 80%;
    height: 34px;
    padding: 6px 12px;
    margin: 0 8px;
    font-size: 14px;
    line-height: 1.42857;
    color: #777;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.selector .selector-available p, .selector .selector-chosen p {
    background: rgba(211, 211, 211, 0.2);
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 0 0 7px 0;
}
/* selector chooseall and clearall button spacing */
a.selector-chooseall {
    padding: 0px 20px 3px 0;
}
a.selector-clearall {
    padding: 0px 0 3px 20px;
}

ul.selector-chooser {
  padding: 0;
}

.checkbox{
    padding-bottom: 5px;
}
.checkbox label {
    margin-bottom: -20px;
}
.checkbox .help-block {
    margin-top: 0;
}

fieldset.collapse {
    height: inherit;
}
.add-row {
    margin-bottom: 20px;
}
legend a:hover {
    text-decoration: none;
}

.wide label {
    width: auto !important;
}


/* change_list actions */
.actions {
    display: inline-block;
}
.actions select {
    margin-top: 5px;
    display: inherit;
    width: inherit;
}
.action-counter {
    margin-left: 5px;
    vertical-align: sub;
}

/* change_list table display */
th.action-checkbox-column {
    width: 1.5em;
    text-align:center;
}

td.action-checkbox {
    text-align:center;
}

.tooltip.in {opacity: 1} /* Hard to see against black text otherwise */

#_changelist {clear: both;}

.scrollable-dropdown-menu {
    overflow-x: hidden;
    max-height: 322px;
}

.scrollable-dropdown-menu li form a {
    clear: both;
    color: #333;
    display: block;
    font-weight: normal;
    line-height: 1.42857;
    padding: 3px 20px;
    white-space: nowrap;
}

#content-main {
    clear: both;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: left;
    display: table;
  }
}
@media (min-width: 970px) {
  .form-horizontal .control-label {
    text-align: right;
    display: table;
  }
}

/* FILE UPLOAD WIDGET*/
p.file-upload {
    line-height: 29px;
    margin: 1;
    padding: 2px 0px 10px 0px;
    color: #666;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
}
p.file-upload > input[type="file"] {
    display: inline;
    margin-left: 5px;
}
