.modal-body-table {
  padding: 0;

  .table {
    margin-bottom: 0;

    th,
    td {
      &:first-child {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
}
