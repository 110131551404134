.select2-container {
  .select2-selection--single {
    height: $input-height !important; // sass-lint:disable-line no-important
    padding: 3px;

    .select2-selection__arrow {
      display: none;
    }
  }

  .select2-selection--multiple {
    padding: 0;
  }

  &.select2-container--open {
    z-index: 9999;
  }
}

.select2-container--default .select2-selection {
  border-color: var(--bs-border-color, --border-color, #aaa) !important;
}
