select#id_items_to {
    height: 250.063px !important;
}

#event-form .table .invalid-feedback {
    display: block;
}

legend.form-label {
    font-size: 1rem;
}