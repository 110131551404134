.image-with-thumbnails {
  display: flex;

  .full-image {
    @extend .align-middle;
    text-align: center;
    height: 415px;
    border: 1px solid $border-color;
    overflow: hidden;
    display: flex;

    img {
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .thumbnails {
    display: flex;
    flex-direction: column;

    .thumbnail-container {
      width: 75px;
      height: 75px;
      opacity: .5;
      cursor: pointer;
      display: flex;

      &.current,
      &:hover {
        opacity: 1;
      }
    }

    img {
      width: 100%;
      max-width: calc(72.5px - .5rem);
      max-height: calc(72.5px - .5rem);
      margin: auto;
    }
  }
}
