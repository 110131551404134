.alert {
  ul {
    margin-bottom: 0;
  }
}

.errorlist {
  padding: 0.75rem 1.25rem .75rem 2rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}