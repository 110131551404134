.table {
  &.table-bordered {
    thead {
      tr {
        border: 0;
      }

      th {
        background-color: #f8f9fa;
      }
    }
  }

  &.table-hover {
    tr {
      &:hover {
        .table-warning {
          background-color: tint-color($warning, 20%);
        }
      }

      td {
        &.warning {
          background-color: tint-color($warning, 25%);
          border-color: tint-color($warning, 5%);
        }
      }
    }
  }

  &.table-with-subtables {
    tr {
      &.even {
        background-color: shade-color(#FFF, 2.5%);

        &:hover {
          background-color: shade-color(#FFF, 7.5%);
        }
      }
    }
  }

  ul {
    margin-bottom: 0;
  }

  .form-group {
    margin-bottom: 0;
  }

  .input-group {
    flex-wrap: nowrap;
  }

  .datepickerinput {
    min-width: 8rem;

    &::parent {
      flex-wrap: nowrap;
    }
  }

  .datetimepickerinput {
    min-width: 10rem;

    &::parent {
      flex-wrap: nowrap;
    }
  }

  .numberinput {
    min-width: 6rem;
  }
}