.notifications-card {
  .card-body {
    background-color: #fcfcfc;
    .box {
      background-color: #f0f0f0;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
  }

  @each $color, $value in $theme-colors {
    $class-name: $color;
    @if $color == "danger" {
      $class-name: "error";
    }

    .item-#{$class-name} {
      .icon {
        color: color-contrast($value);
        background-color: #{$value};
      }
    }
  }
}

.announcement {
  background-color: #f0f0f0;
}

.dashboard-group {
  .card-header {
    background-color: #e6e6e6;;
  }
}
