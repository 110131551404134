.public-fieldset-card {
  .card-header {
    .form-group {
      float: left;
    }

    .help-icon {
      float: right;
    }
  }
}

#item-edit-platform-tabs {
  .accordion-list {
    .list-group {
      &.collapse {
        &.show {
          background-color: darken($card-cap-bg, 8%);
        }
      }
    }
  }

  .platform-tab {
    &.list-group-item-action {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin: 0;
      padding: 1rem;
    }
  }

  .platform-list-group {
    &.selected {
      background-color: $card-cap-bg;
      border-left: 3px solid $primary;
    }
  }

  .list-group-item-action {
    background: transparent;
    border-left: 3px solid $black;
    border-color: transparent;
    border-radius: 5px;
    width: unset;
    color: $gray-700;

  .list-group-item-action i.fa {
    color: #d32626;
    padding-left: 1rem;
    align-self: center;
  }

    &.active {
      background-color: $primary;
      color: $white;
    }
  }

  .fa, .far {
    font-weight: bold;
    padding-left: 15px;
  }
}

#catalog-item-form .nav-tabs {
  .fa, .far {
    font-weight: bold;
    padding-left: 5px;
  }
}

i.fa.fa-exclamation-circle {
  color: #d32626;
}

.warehouse-inventory-block {
  margin-bottom: 1rem;

  .form-group {
    margin-bottom: .3rem;
  }
}

#warehouse-modal {
  .form-group {
    display: flex;
    flex-wrap: wrap
  }

  #inventory-save {
    i {
      display: none;
      margin-right: .3rem;
    }
    &:disabled i {
      display: inline-block;
    }
  }
}
