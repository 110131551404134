.context-select {
  margin-top: -1rem;
}

.item-detail {
  max-width: 1200px;
  margin: auto;

  >* {
    @extend .mb-4;
  }

  .detail {
    @extend .row, .mb-3;

    .detail-name {
      @extend .fw-bold;
      @extend .pb-0, .pb-lg-2, .pb-xl-0;
    }

    .detail-name,
    .detail-value {
      @extend .col-6, .col-lg-12, .col-xl-6;
    }
  }

  .images,
  .details {
    @extend .col-12, .col-lg-6;
  }

  .details {
    @extend .order-2, .order-lg-1;

    .esrb-descriptor {
      list-style: none;
      padding-bottom: .5rem;
    }
  }

  .images {
    @extend .order-1, .order-lg-2;
  }
}

#manage-dlc-modal,
#manage-accessories-modal {

  tr.digitalcontent-row,
  tr.assortment-row {
    background: #f5f6fa;
  }

  td.action-items {
    text-align: right;
  }

  td.addons-link {
    text-align: center;
  }
}